.specsheet {
    font-family: $font-family-sans-serif;

    .columns {
        margin-top: $spacer--semi-medium;
    }

    .product-view__heading {
        margin-top: 0;
    }

    .product-view__details {
        margin-bottom: $spacer--medium;
        float: left;
        width: 50%;

        .product-view__overview {
            .label {
                font-family: $font-family-sans-serif;
            }
        }
    }

    .product-view__sku {
        display: block;
    }

    .product-view__info-detailed {
        clear: both;

        &> p {
            color: $gray-darker;
            font-size: $font-size-base;
            line-height: 24px;
        }
    }

    .product-view__media {
        margin-bottom: $spacer--semi-medium;
        text-align: center;
        box-sizing: border-box;
        float: left;
        width: 50%;
        padding-right: $spacer--semi-medium;

        img {
            display: inline-block;
            width: 100%;
        }
    }
}
